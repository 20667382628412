import React, { useEffect, useRef, useState } from "react";
import locations from "../data/locations.json";
import trails from "../data/trails.json";

const MapWithControls = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [expandedTrail, setExpandedTrail] = useState(null);
  const trailMarkers = useRef({});
  const trailDirections = useRef({});
  const directionsService = useRef(null);
  const directionsRenderer = useRef({});
  const activeInfoWindow = useRef(null);

  const trailColors = {
    "Full Map": "blue",
    "Urban Hops Loop": "orange",
    "City Sips Circuit": "blue",
    "East End Tap Trek": "green",
    "Northern Ale Excursion": "purple",
    "Southtowns Sip Circuit": "teal",
    "Ale Outposts": "red",
  };

  useEffect(() => {
    const initMap = () => {
      const googleMap = new window.google.maps.Map(mapRef.current, {
        center: { lat: 42.8864, lng: -78.8784 },
        zoom: 10,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#f5deb3" }] },
          { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          { featureType: "water", stylers: [{ color: "#c9c9c9" }] },
        ],
      });
      setMap(googleMap);

      directionsService.current = new window.google.maps.DirectionsService();

      Object.keys(trailColors).forEach((trail) => {
        const trailLocations = locations.filter(
          (location) => location.trail === trail || trail === "Full Map"
        );
        const color = trailColors[trail] || "red";

        trailMarkers.current[trail] = trailLocations.map((location) => {
          const marker = new window.google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map: googleMap,
            title: location.name,
            icon: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
          });

          const infoWindow = new window.google.maps.InfoWindow({
            content: `
              <div style="text-align: center;">
                <img src="${location.logo}" alt="${location.name}" style="width: 50px; height: 50px; border-radius: 50%; margin-bottom: 10px;" />
                <h4 style="margin: 0;">${location.name}</h4>
                <p style="margin: 5px 0;">${location.address}</p>
                <p style="margin: 5px 0;">${location.offering}</p>
                <a href="${location.websiteUrl}" target="_blank" style="color: #007BFF; display: block; margin-bottom: 5px;">Visit Website</a>
                <a href="https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}" target="_blank" style="color: #007BFF;">Get Directions</a>
              </div>
            `,
          });

          marker.addListener("click", () => {
            if (activeInfoWindow.current) {
              activeInfoWindow.current.close();
            }
            infoWindow.open({
              anchor: marker,
              map: googleMap,
              shouldFocus: false,
            });
            activeInfoWindow.current = infoWindow;
          });

          return marker;
        });
      });
    };

    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC1srWDOxOsdgJL9ln30SYRRwz_PgCOXys`;

      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.body.appendChild(script);
    };

    if (!window.google || !window.google.maps) {
      loadGoogleMapsScript();
    } else {
      initMap();
    }
  }, []);

  const zoomToTrail = (trail) => {
    if (!map || !trailMarkers.current[trail]) return;

    const bounds = new window.google.maps.LatLngBounds();
    trailMarkers.current[trail].forEach((marker) => {
      bounds.extend(marker.getPosition());
    });
    map.fitBounds(bounds);
  };

  const handleTrailClick = (trail) => {
    setExpandedTrail(expandedTrail === trail ? null : trail);
    zoomToTrail(trail);

    if (trail !== "Ale Outposts" && trail !== "Full Map") {
      displayTrailDirections(trail);
    }
  };

  const displayTrailDirections = (trail) => {
    const trailLocations = locations.filter((loc) => loc.trail === trail);

    if (trailLocations.length > 1) {
      const waypoints = trailLocations.slice(1, -1).map((location) => ({
        location: { lat: location.lat, lng: location.lng },
        stopover: true,
      }));

      directionsService.current.route(
        {
          origin: { lat: trailLocations[0].lat, lng: trailLocations[0].lng },
          destination: {
            lat: trailLocations[trailLocations.length - 1].lat,
            lng: trailLocations[trailLocations.length - 1].lng,
          },
          waypoints,
          travelMode: "DRIVING",
        },
        (result, status) => {
          if (status === "OK") {
            if (!directionsRenderer.current[trail]) {
              directionsRenderer.current[trail] =
                new window.google.maps.DirectionsRenderer({
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: trailColors[trail],
                    strokeWeight: 4,
                  },
                });
            }
            directionsRenderer.current[trail].setDirections(result);
            directionsRenderer.current[trail].setMap(map);
          }
        }
      );
    }
  };

  return (
    <div style={{ backgroundColor: "#925456" }}>
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2 style={{ marginTop: "40px", textAlign: "center", color: "white" }}>
          Map Out Your Beer Adventure
        </h2>

        <p style={{ color: "white" }}>
          Explore the best breweries, meaderies, and distilleries Western New
          York has to offer. Choose a trail to discover unique local spots, or
          view the full map to plan your own adventure. Drink responsibly and
          enjoy the journey!
        </p>
      </div>
      <div className="map-container">
        {/* Sidebar with Trail Buttons */}
        <div className="map-controls">
          {["Full Map", ...trails.map((trail) => trail.name)].map((trail) => (
            <div key={trail}>
              <button
                style={{
                  backgroundColor: trailColors[trail] || "gray", // Use trail color
                  "--button-color": trailColors[trail] || "gray", // Pass color to CSS
                }}
                onClick={() => handleTrailClick(trail)}
              >
                {trail}
              </button>
              {expandedTrail === trail && trail !== "Full Map" && (
                <div style={{ padding: "10px", backgroundColor: "#f9f9f9" }}>
                  <p>{trails.find((t) => t.name === trail)?.description}</p>
                  <ol style={{ margin: 0, paddingLeft: "20px" }}>
                    {locations
                      .filter((loc) => loc.trail === trail)
                      .map((loc) => (
                        <li key={loc.name} style={{ marginBottom: "10px" }}>
                          <strong>{loc.name}</strong>
                          <br />
                          <span>{loc.address}</span>
                        </li>
                      ))}
                  </ol>
                  <p>
                    <a
                      href={`https://www.google.com/maps/dir/?api=1&waypoints=${locations
                        .filter((loc) => loc.trail === trail)
                        .map((loc) => `${loc.lat},${loc.lng}`)
                        .join("|")}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#007BFF",
                        textDecoration: "underline",
                      }}
                    >
                      Trail Directions
                    </a>{" "}
                    |{" "}
                    <a
                      href="https://www.dezrideclub.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#007BFF",
                        textDecoration: "underline",
                      }}
                    >
                      Use a Designated Driver
                    </a>
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Map View */}
        <div ref={mapRef} className="map-view" />
      </div>
    </div>
  );
};

export default MapWithControls;
