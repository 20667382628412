import React, { useState, useEffect } from "react";
import styled from "styled-components";
import client from "../sanityClient"; // Import the configured sanityClient.js

// Styled Components
const HeroWrapper = styled.header`
  position: relative;
  min-height: 100vh;
  color: #fff;
  text-align: center;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  /* Video Styling */
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Image Styling */
  background: url("/img/intro-bg.png") center center no-repeat;
  background-size: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for readability */
  z-index: -1;
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 80px 20px;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 400px;
    padding: 20px;
  }
`;

const Logo = styled.div`
  margin-bottom: 30px;

  img {
    max-width: 150px;
    height: auto;

    @media (max-width: 768px) {
      max-width: 120px;
    }
  }
`;

const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const ButtonGroup = styled.div`
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Button = styled.a`
  display: inline-block;
  color: #fff;
  padding: 12px 25px;
  border-radius: 5px;
  text-decoration: none;
  margin: 5px;
  font-size: 16px;

  &.primary {
    background-color: #ff9800;
  }

  &.secondary {
    background-color: #555;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const ProgressContainer = styled.div`
  margin: 0 auto;
  max-width: 300px;
`;

const ProgressTitle = styled.h3`
  color: #fff;
  margin-bottom: 15px;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const ProgressBar = styled.div`
  background: #ccc;
  border-radius: 10px;
  overflow: hidden;
  height: 25px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    height: 20px;
    margin-bottom: 10px;
  }
`;

const ProgressBarFill = styled.div`
  background: #ff9800;
  height: 100%;
  transition: width 1.5s ease;
`;

const ProgressText = styled.p`
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

// Hero Component
export const Hero = () => {
  const [beerCardData, setBeerCardData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [useVideo, setUseVideo] = useState(false);

  const cardPrice = 40; // Price per card
  const goal = 120000; // Goal to raise

  useEffect(() => {
    // Fetch Beer Card Count from Sanity
    client
      .fetch(
        `*[_type == "beerCardCount"][0]{
           cardsSold,
           totalCards
         }`
      )
      .then((data) => {
        if (data) {
          setBeerCardData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching beer card data from Sanity:", error);
      });

    // Check if the user's internet speed is fast enough to load a video
    const checkInternetSpeed = async () => {
      const startTime = new Date().getTime();
      try {
        await fetch("https://www.google.com/favicon.ico", { mode: "no-cors" });
        const endTime = new Date().getTime();
        const duration = endTime - startTime;

        // If the fetch request is very quick (less than 200ms), assume fast internet
        if (duration < 200) {
          setUseVideo(true);
        }
      } catch (error) {
        console.error("Internet speed check failed:", error);
      }
    };

    checkInternetSpeed();
  }, []);

  useEffect(() => {
    // Simulate the progress animation
    if (beerCardData) {
      const progressPercentage =
        ((beerCardData.cardsSold * cardPrice) / goal) * 100;
      const timeout = setTimeout(() => {
        setProgress(progressPercentage);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, [beerCardData]);

  const headerData = {
    title: "Explore WNY's Best Breweries, Bars, and Distilleries!",
    paragraph:
      "Get your 2025 Craft Brew Tour Card and enjoy free drinks at 34 participating locations while supporting Habitat for Humanity Buffalo.",
  };

  if (!beerCardData) return <p>Loading...</p>;

  const { cardsSold, totalCards } = beerCardData;
  const amountRaised = cardsSold * cardPrice;

  return (
    <HeroWrapper>
      {/* Background */}
      <Background>
        {useVideo && (
          <video autoPlay loop muted playsInline>
            <source src="/video/beer.mp4" type="video/mp4" />
          </video>
        )}
      </Background>

      <Overlay />

      {/* Content */}
      <HeroContent>
        <Logo>
          <img src="/img/logo-white.webp" alt="Habitat for Humanity Buffalo" />
        </Logo>
        <Title>{headerData.title}</Title>
        <Paragraph>{headerData.paragraph}</Paragraph>
        <ButtonGroup>
          <Button
            href="https://habitatbuffalo.betterworld.org/events/2025-craft-brew-tour-cards"
            target="_blank"
            rel="noopener noreferrer"
            className="primary"
          >
            Get Your Beer Card
          </Button>
          <Button href="#map" className="secondary">
            Explore the Trail
          </Button>
        </ButtonGroup>
        <ProgressContainer>
          <ProgressTitle>Progress to Our Goal</ProgressTitle>
          <ProgressBar>
            <ProgressBarFill style={{ width: `${progress}%` }} />
          </ProgressBar>
          <ProgressText>
            We've raised ${amountRaised.toLocaleString()} so far,{" "}
            {Math.round(progress)}% of our $120,000 goal!
            <br /> <br />
            This goal will allow Habitat For Humanity Buffalo to build a home
            for a family in need right here in Buffalo! Help us by drinking
            beer!
          </ProgressText>
        </ProgressContainer>
      </HeroContent>
    </HeroWrapper>
  );
};
