import React, { useState } from "react";
import locations from "../data/locations.json";
import trails from "../data/trails.json";

const Trails = () => {
  const [expandedTrail, setExpandedTrail] = useState(null);
  const trailColors = {
    "Full Map": "#007BFF", // Default blue
    "Urban Hops Loop": "#FF6F00", // Orange
    "City Sips Circuit": "#5ca9fb", // Light Blue
    "East End Tap Trek": "#4CAF50", // Green
    "Northern Ale Excursion": "#9C27B0", // Purple
    "Southtowns Sip Circuit": "#008080", // Teal
    "Ale Outposts": "#FF0000", // Red
  };

  const handleAccordionClick = (trailName) => {
    setExpandedTrail(expandedTrail === trailName ? null : trailName);
  };

  const shareTrail = (trailName) => {
    const url = window.location.href; // Replace with trail-specific URL if available
    const message = `I'm exploring the ${trailName} on the Western New York Beer Trail! Join me for amazing breweries, distilleries, and more. Check it out: ${url}`;
    navigator.share
      ? navigator.share({ title: trailName, text: message, url })
      : alert(message);
  };

  const checkInLocation = (location) => {
    const message = `I just checked in at ${location.name} on the Western New York Beer Trail! 🍺 Explore this spot and others: ${location.websiteUrl}`;
    navigator.share
      ? navigator.share({ text: message, url: location.websiteUrl })
      : alert(message);
  };

  return (
    <div
      style={{
        background: `url('img/trails-background.webp') center/cover no-repeat`,
        position: "relative",
        color: "#333",
        padding: "40px 20px",
      }}
    >
      {/* Light Overlay for Readability */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)", // Light overlay effect
          zIndex: 1,
        }}
      ></div>

      {/* Content Section */}
      <div style={{ position: "relative", zIndex: 2 }}>
        <h2 style={{ padding: "20px", textAlign: "center" }}>
          Explore Six Unique Trails, Or Chart Your Own Path!
        </h2>
        <p style={{ color: "#555", lineHeight: "1.6" }}>
          Discover the best breweries, distilleries, and more across six unique
          trails. Each trail offers its own charm, with vibrant locations to
          explore. Remember to <strong>drink responsibly</strong>, never drink
          and drive, and use a designated driver service like{" "}
          <a
            href="https://www.dezrideclub.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007BFF" }}
          >
            Designated Drivers of Buffalo
          </a>
          . Trails are meant to be enjoyed over time—pace yourself and don’t
          forget to tip your bartender!
        </p>

        {/* Main Accordion for Trails */}
        {trails.map((trail) => {
          const isExpanded = expandedTrail === trail.name;
          const trailLocations = locations.filter(
            (loc) => loc.trail === trail.name
          );

          return (
            <div key={trail.name} className="trail-locations-grid">
              {/* Accordion Header */}
              <div
                style={{
                  cursor: "pointer",
                  padding: "15px 20px",
                  backgroundColor: trailColors[trail.name] || "#ccc",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => handleAccordionClick(trail.name)}
              >
                {trail.name} {isExpanded ? "▼" : "➤"}
              </div>

              {/* Accordion Content */}
              {isExpanded && (
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <p>{trail.description}</p>
                  <p>
                    <strong>Estimated Time:</strong> {trail.estimated_time}
                  </p>
                  <p>
                    <strong>Difficulty:</strong> {trail.difficulty}
                  </p>
                  <p>
                    <a
                      href={`https://www.google.com/maps/dir/?api=1&waypoints=${trailLocations
                        .map((loc) => `${loc.lat},${loc.lng}`)
                        .join("|")}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#007BFF", textDecoration: "underline" }}
                    >
                      View Trail Directions
                    </a>
                  </p>
                  <button
                    onClick={() => shareTrail(trail.name)}
                    style={{
                      padding: "10px 15px",
                      backgroundColor: trailColors[trail.name],
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    Share this Trail
                  </button>

                  {/* Locations Grid */}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(200px, 1fr))",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {trailLocations.map((location) => (
                      <div
                        key={location.name}
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          padding: "15px",
                          textAlign: "center",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={location.logo}
                          alt={location.name}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            marginBottom: "10px",
                          }}
                        />
                        <h4 style={{ margin: "0", fontSize: "16px" }}>
                          {location.name}
                        </h4>
                        <p style={{ margin: "5px 0", color: "#555" }}>
                          {location.address}
                        </p>
                        <a
                          href={location.websiteUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#007BFF",
                            display: "block",
                            marginBottom: "5px",
                          }}
                        >
                          Visit Website
                        </a>
                        <a
                          href={`https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#007BFF",
                            textDecoration: "underline",
                            display: "block",
                            marginBottom: "5px",
                          }}
                        >
                          Get Directions
                        </a>
                        <button
                          onClick={() => checkInLocation(location)}
                          style={{
                            padding: "5px 10px",
                            backgroundColor: trailColors[trail.name],
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "12px",
                            cursor: "pointer",
                          }}
                        >
                          Check In
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Trails;
