import { useState } from "react";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
  status: "",
  submitted: false, // New field to track submission status
};

export const Contact = (props) => {
  const [{ name, email, message, submitted }, setState] =
    useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const response = await fetch("https://formspree.io/f/xvgovgnk", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
        mode: "no-cors",
      });

      setState((prevState) => ({
        ...prevState,
        submitted: true, // Mark as submitted
      }));
    } catch (error) {
      console.error("Error sending message", error);
      setState((prevState) => ({
        ...prevState,
        status: "An error occurred, please try again.",
      }));
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2 style={{ marginTop: "20px" }}>
                  {submitted ? "Thank You!" : "Contact Us"}
                </h2>

                {!submitted ? (
                  <p>
                    Have questions about the Craft Brew Tour Card? Reach out and
                    we’ll get back to you as soon as possible!
                  </p>
                ) : (
                  <p>
                    Thank you for your message! We will get back to you soon.
                  </p>
                )}
              </div>
              {!submitted && (
                <form name="sentMessage" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Your Name"
                          required
                          value={name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Your Email"
                          required
                          value={email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Your Message"
                      required
                      value={message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Information</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address:
                </span>{" "}
                {props.data ? props.data.address : "Loading..."}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone:
                </span>{" "}
                {props.data ? props.data.phone : "Loading..."}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope"></i> Email:
                </span>{" "}
                {props.data ? props.data.email : "Loading..."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; {new Date().getFullYear()} Habitat for Humanity Buffalo</p>
        </div>
      </div>
    </div>
  );
};
