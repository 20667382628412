import React, { useState } from "react";
import styled from "styled-components";
import locations from "../data/locations.json";

// Styled Components for Background
const BackgroundWrapper = styled.div`
  padding: 20px;
  text-align: center;
  background: url("img/locations.webp") center/cover no-repeat;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  position: relative;
  color: #333;

  /* Overlay for better text readability */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

// Styled Components for Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #ff9800;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;

  &.directions {
    background-color: #007bff;
  }

  &.website {
    background-color: #ff9800;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const Participants = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const openModal = (location) => {
    setSelectedLocation(location);
  };

  const closeModal = () => {
    setSelectedLocation(null);
  };

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <h2>Participating Locations</h2>
        <p>
          Discover more about our incredible participating locations by clicking
          on their logos below.
        </p>

        {/* Grid of logos */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
            gap: "20px",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          {locations.map((location, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                textAlign: "center",
                cursor: "pointer",
                transition: "transform 0.3s ease",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add slight shadow to make logos pop
              }}
              onClick={() => openModal(location)}
            >
              <img
                src={location.logo}
                alt={location.name}
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  objectFit: "contain",
                  marginBottom: "10px",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
          ))}
        </div>

        {/* Modal */}
        {selectedLocation && (
          <ModalOverlay onClick={closeModal}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <CloseButton onClick={closeModal}>&times;</CloseButton>
              <img
                src={selectedLocation.logo}
                alt={selectedLocation.name}
                style={{
                  maxWidth: "150px",
                  marginBottom: "20px",
                }}
              />
              <h2 style={{ color: "#333", marginBottom: "10px" }}>
                {selectedLocation.name}
              </h2>
              <p style={{ color: "#555", marginBottom: "10px" }}>
                <strong>Address:</strong> {selectedLocation.address || "N/A"}
              </p>
              <p style={{ color: "#555", marginBottom: "10px" }}>
                <strong>Offering:</strong> {selectedLocation.offering || "N/A"}
              </p>
              {selectedLocation.terms &&
                selectedLocation.terms.trim() !== "N/A" && (
                  <p style={{ color: "#555", marginBottom: "10px" }}>
                    <strong>Terms:</strong> {selectedLocation.terms}
                  </p>
                )}
              <p style={{ color: "#555", marginBottom: "10px" }}>
                <strong>Trail:</strong> {selectedLocation.trail || "N/A"}
              </p>
              <ButtonGroup>
                <Button
                  href={`https://www.google.com/maps?q=${encodeURIComponent(
                    selectedLocation.address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="directions"
                >
                  Get Directions
                </Button>
                <Button
                  href={selectedLocation.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="website"
                >
                  Visit Website
                </Button>
              </ButtonGroup>
            </ModalContent>
          </ModalOverlay>
        )}
      </ContentWrapper>
    </BackgroundWrapper>
  );
};

export default Participants;
