import React from "react";

export const About = (props) => {
  return (
    <div
      id="about"
      style={{
        background: `url('img/about-background.webp') center/cover no-repeat`,
        position: "relative",
        padding: "160px 0", // Increased padding for more breathing room
        color: "#333",
      }}
    >
      {/* Overlay for lighter effect */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)", // Lighter overlay
          zIndex: 1,
        }}
      ></div>

      {/* Content Section */}
      <div className="container" style={{ position: "relative", zIndex: 2 }}>
        <div className="row">
          {/* Image Section */}
          <div
            className="col-xs-12 col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="img/about-card.jpg"
              className="img-responsive"
              alt="Craft Brew Tour Card"
              style={{
                maxWidth: "100%", // Increased image size
                height: "auto",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.6)",
              }}
            />
          </div>
          {/* Text Section */}
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 className="custom-heading">Sip. Savor. Repeat.</h2>
              <p style={{ lineHeight: "1.6" }}>
                {props.data
                  ? props.data.paragraph
                  : "The Craft Brew Tour Card is your exclusive passport to explore the best breweries, distilleries, and bars in Western New York. Enjoy one free drink at each of the 34 participating locations while supporting Habitat for Humanity Buffalo!"}
              </p>
              <h3>Why Get a Craft Brew Tour Card?</h3>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  marginTop: "20px",
                  lineHeight: "1.8",
                }}
              >
                <li>
                  34 drinks at WNY's best breweries, distilleries, taprooms, and
                  more!
                </li>
                <li>6 unique beer trails to explore</li>
                <li>Exclusive events, tastings, and tours</li>
                <li>Invitation to the house dedication ceremony</li>
              </ul>

              {/* Call-to-Action Button */}
              <div style={{ marginTop: "30px" }}>
                <a
                  href="https://habitatbuffalo.betterworld.org/events/2025-craft-brew-tour-cards"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    padding: "15px 25px",
                    background: "#ff9800",
                    color: "#fff",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    textDecoration: "none",
                    fontSize: "18px",
                    transition: "background 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.background = "#ff7200")}
                  onMouseOut={(e) => (e.target.style.background = "#ff9800")}
                >
                  Get Your Card Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
