import React, { useState, useEffect } from "react";
import client from "../sanityClient"; // Import the configured sanity client

export const Habitat = () => {
  const [beerCardData, setBeerCardData] = useState(null); // State to hold fetched data
  const cardPrice = 40; // Price per card
  const goal = 120000; // Goal to raise

  useEffect(() => {
    // Fetch Beer Card Count from Sanity
    client
      .fetch(
        `*[_type == "beerCardCount"][0]{
           cardsSold,
           totalCards
         }`
      )
      .then((data) => {
        if (data) {
          setBeerCardData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching beer card data from Sanity:", error);
      });
  }, []);

  if (!beerCardData) {
    return <p>Loading...</p>;
  }

  const { cardsSold, totalCards } = beerCardData;

  // Calculate the amount raised and progress percentage
  const amountRaised = cardsSold * cardPrice;
  const progressPercentage = (amountRaised / goal) * 100;

  return (
    <div
      id="habitat-info"
      style={{
        background: "#e9ecef",
        padding: "60px 20px",
        textAlign: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img
              src="img/habitat-logo.webp"
              alt="Habitat for Humanity Buffalo"
              style={{
                maxWidth: "200px",
                height: "auto",
                marginBottom: "20px",
              }}
            />
          </div>
          <div className="col-12">
            <h2 style={{ color: "#333", marginBottom: "20px" }}>
              About Habitat for Humanity Buffalo
            </h2>
            <p
              style={{
                color: "#555",
                fontSize: "18px",
                marginBottom: "40px",
              }}
            >
              Habitat for Humanity Buffalo is a nonprofit organization dedicated
              to building affordable homes for families in need in our local
              community. We work alongside families to help them achieve
              homeownership through affordable housing programs. Since its
              inception, Habitat for Humanity Buffalo has built homes every
              year, and through the "House That Beer Built" campaign, we've
              raised funds to construct homes right here in Buffalo.
            </p>

            <h3 style={{ color: "#333", marginBottom: "20px" }}>
              8th Annual House That Beer Built Campaign
            </h3>
            <p
              style={{
                color: "#555",
                fontSize: "18px",
                marginBottom: "40px",
              }}
            >
              This is our 8th Annual House That Beer Built campaign! Over the
              years, this initiative has raised over $120,000, funding the
              construction of a new home for a deserving family in Buffalo. With
              the help of local breweries, bars, and restaurants, this campaign
              has created a lasting impact on our community. Your participation
              in buying the Craft Brew Tour Card not only gives you access to
              amazing breweries, but it also helps provide a safe, affordable
              home for those in need.
            </p>

            <h3 style={{ color: "#333", marginBottom: "20px" }}>
              How You Can Help
            </h3>
            <p
              style={{
                color: "#555",
                fontSize: "18px",
                marginBottom: "40px",
              }}
            >
              By purchasing a Craft Brew Tour Card, you support this impactful
              campaign and help build a home for a family right here in Buffalo.
              There are many ways to get involved, from buying a card to
              volunteering or donating. Visit our website to learn more about
              how you can help make a difference in the lives of families in
              need.
            </p>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="https://habitatbuffalo.betterworld.org/events/2025-craft-brew-tour-cards"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  padding: "12px 24px",
                  fontSize: "18px",
                  backgroundColor: "#ff9800",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  textDecoration: "none",
                  transition: "background-color 0.3s",
                  marginTop: "20px",
                  marginRight: "10px",
                }}
              >
                Get Your Craft Brew Tour Card
              </a>
              <a
                href="https://www.habitatbuffalo.org/get-involved"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  padding: "12px 24px",
                  fontSize: "18px",
                  backgroundColor: "#4CAF50",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  textDecoration: "none",
                  transition: "background-color 0.3s",
                  marginTop: "20px",
                }}
              >
                Learn More About Habitat
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Progress Bar */}
      <div style={{ marginTop: "40px", width: "100%" }}>
        <h3 style={{ color: "#333", marginBottom: "10px" }}>
          Progress to Our Goal
        </h3>
        <div
          style={{
            height: "20px",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${progressPercentage}%`,
              backgroundColor: "#ff9800",
              borderRadius: "10px",
            }}
          ></div>
        </div>
        <p style={{ color: "#555", marginTop: "10px" }}>
          We're {Math.round(progressPercentage)}% of the way to our $120,000
          goal! This goal will help us build a home for a family in need right
          here in Buffalo.
        </p>
      </div>

      {/* Gallery Section */}
      <div style={{ paddingTop: "40px", textAlign: "center" }}>
        <div
          className="gallery"
          style={{
            display: "flex",
            overflowX: "scroll",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <img
            src="img/habitat/1.webp"
            alt="Gallery Image 1"
            style={{
              width: "400px",
              height: "auto",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          />
          <img
            src="img/habitat/5.jpg"
            alt="Gallery Image 2"
            style={{
              width: "400px",
              height: "auto",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          />
          <img
            src="img/habitat/3.webp"
            alt="Gallery Image 3"
            style={{
              width: "400px",
              height: "auto",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          />
          <img
            src="img/habitat/4.jpg"
            alt="Gallery Image 4"
            style={{
              width: "400px",
              height: "auto",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          />
        </div>
      </div>
    </div>
  );
};
