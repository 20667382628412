import React, { useState } from "react";
import SmoothScroll from "smooth-scroll";

export const Navigation = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Initialize SmoothScroll
  const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });

  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    const targetId = e.target.getAttribute("href"); // Get the target section ID
    const targetElement = document.querySelector(targetId); // Find the target element
    if (targetElement) {
      scroll.animateScroll(targetElement); // Smoothly scroll to the element
      setSidebarOpen(false); // Close the sidebar after clicking (for mobile)
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            onClick={toggleSidebar}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src="/img/logo-black.webp"
              alt="Habitat for Humanity Buffalo"
              style={{
                maxWidth: "60px",
                height: "auto",
              }}
            />
            <span>Craft Brew Tour Cards</span>
          </a>
        </div>

        <div
          className={`navbar-collapse ${sidebarOpen ? "show" : ""}`}
          id="navbar-menu"
        >
          {/* Close Button */}
          <button className="close-btn" onClick={toggleSidebar}>
            &times;
          </button>

          <ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="#about"
                className="page-scroll"
                onClick={handleLinkClick}
              >
                About
              </a>
            </li>
            <li>
              <a href="#map" className="page-scroll" onClick={handleLinkClick}>
                Map
              </a>
            </li>
            <li>
              <a
                href="#trails"
                className="page-scroll"
                onClick={handleLinkClick}
              >
                Trails
              </a>
            </li>
            <li>
              <a
                href="#locations"
                className="page-scroll"
                onClick={handleLinkClick}
              >
                Locations
              </a>
            </li>
            <li>
              <a
                href="https://habitatbuffalo.betterworld.org/events/2025-craft-brew-tour-cards"
                className="btn btn-custom btn-lg page-scroll"
                target="blank"
                style={{
                  color: "#fff",
                  backgroundColor: "#ff9800",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  marginLeft: "10px",
                }}
              >
                Buy Card
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
